import React, { useEffect, useState } from 'react';
//import {find} from "lodash";
import Modal from 'react-bootstrap/Modal';
import { graphql } from 'gatsby'

import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import FindOut from "gatsby-theme-starberry-lomondgroup/src/components/FindOut/FindOut";
import Info from "gatsby-theme-starberry-lomondgroup/src/components/Info/Info"
import Features from "gatsby-theme-starberry-lomondgroup/src/components/Features/Features";
import Customer from "gatsby-theme-starberry-lomondgroup/src/components/Customer/Customer";
import Reviews from "gatsby-theme-starberry-lomondgroup/src/components/Reviews/Reviews";
import News from "gatsby-theme-starberry-lomondgroup/src/components/News/News"
import Journey from "gatsby-theme-starberry-lomondgroup/src/components/Journey/Journey";
//import Banner from "gatsby-theme-starberry-lomondgroup/src/components/Banner/Banner"
import LeadooScript from "gatsby-theme-starberry-lomondgroup/src/components/LeadooScript/LeadooScript";
import BannerSlider from "gatsby-theme-starberry-lomondgroup/src/components/BannerSlider/Slider";

import TrustpilotReviews from "gatsby-theme-starberry-lomondgroup/src/components/TrustpilotReviews/TrustpilotReviews";
import HomeBanner from "gatsby-theme-starberry-lomondgroup/src/components/Banner/HomeBanner";

import ImageTextCarousal from "gatsby-theme-starberry-lomondgroup/src/components/ImageTextCarousal/ImageTextCarousal"
import TilesBlock from "gatsby-theme-starberry-lomondgroup/src/components/TilesBlock/Tiles"

import {RenderComponent} from "gatsby-theme-starberry-lomondgroup/src/modules/render-component";

// const Banner = loadable(() =>
//   import("gatsby-theme-starberry-lomondgroup/src/components/Banner/Banner")
// )
// const BannerSlider = loadable(() =>
//   import("gatsby-theme-starberry-lomondgroup/src/components/BannerSlider/Slider")
// )
// const LeadooScript = loadable(() =>
//   import("gatsby-theme-starberry-lomondgroup/src/components/LeadooScript/LeadooScript")
// )

const HomepageTemplate = (props) =>{

  const renderComponent = RenderComponent()

  // var GQLPage = props.GQLPage;
  // var GQLModules = props.GQLModules;

  const [modalShow, setModalShow] = useState(true)
  const [count, setCount] = useState(0);

  var GQLPage = props?.data?.glstrapi?.page;
  var GQLModules = props?.data?.glstrapi?.page?.add_components;
  var SiteConfig = props?.data?.glstrapi?.siteConfig;


  const closeModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    // Access count value from session storage
    var pageView = sessionStorage.getItem("pageView");
    if (pageView == null) {
      // Initialize page views count
      pageView = 1;
    } else {
      // Increment count
      pageView = Number(pageView) + 1;
    }
    // Update session storage
    sessionStorage.setItem("pageView", pageView);
    setCount(pageView);
  }, []);

  var isJourneySec = GQLModules.find((o) => { return o.__typename === "GLSTRAPI_ComponentComponentsShowPropertyJourney" && o.show });


  let pathname = GQLPage?.menu?.slug ? GQLPage?.menu?.slug.replace(/-/g, '_') : "";

  //console.log("pathname =>", pathname);

  //console.log("RenderComponent ==>", renderComponent)

  return(
    <Layout seo_meta={GQLPage.seo_meta} isJourneySec={isJourneySec} menu_slug={GQLPage?.menu?.slug} popular_search={GQLPage?.menu?.popular_search} GQLPage={GQLPage}>
      
        {GQLPage?.banner_section?.banner_image && <HomeBanner banner_section = {GQLPage.banner_section} GQLPage={GQLPage}/> }

        {GQLModules?.length > 0 && GQLModules?.map((Module, index) => {
            return(
              <>
                  {(!GQLPage?.banner_section?.banner_image && Module?.__typename === "GLSTRAPI_ComponentComponentsSliderComponent") && <BannerSlider key={Module} Module={Module} GQLPage={GQLPage}/> }

                  {Module?.__typename === "GLSTRAPI_ComponentComponentsTilesBoxComponent" && renderComponent === "true" && <TilesBlock key={Module} Module={Module} GQLPage={GQLPage} /> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsImageTextCarousel" && renderComponent === "true" && <ImageTextCarousal key={Module} Module={Module} strapi_id={GQLPage.id}/> }

                  {Module.__typename === "GLSTRAPI_ComponentComponentsIntroSection" && <FindOut key={Module} Module={Module} /> }                    

                  {Module.__typename === "GLSTRAPI_ComponentComponentsHomeTilesComponent" && <Info key={Module} Module={Module} GQLPage={GQLPage}  /> }

                  {Module.__typename === "GLSTRAPI_ComponentComponentsTrustpilotComponent" &&  
                  renderComponent === "true" && <TrustpilotReviews key={Module} Module={Module} />
                  }

                  {Module.__typename === "GLSTRAPI_ComponentComponentsShowFeaturedProperty" && Module.show && renderComponent === "true" && <Features key={Module} Module={Module} menu_slug={GQLPage?.menu?.slug}/>}

                  {Module.__typename === "GLSTRAPI_ComponentComponentsVideoSection" &&  <Customer key={Module} Module={Module} GQLPage={GQLPage} />}

                  {Module.__typename === "GLSTRAPI_ComponentComponentsShowCustomerReviews" && Module.show && renderComponent === "true" && <Reviews key={Module} Module={Module}/> }

                  {Module?.__typename === "GLSTRAPI_ComponentComponentsLeadooChatComponent" && <LeadooScript key={Module} Module={Module} /> }

                  {Module.__typename === "GLSTRAPI_ComponentComponentsShowNewsInsight" && Module.show && renderComponent === "true" && <News key={Module} Module={Module}/> }                    

                  {Module.__typename === "GLSTRAPI_ComponentComponentsShowPropertyJourney" && Module.show && <Journey key={Module} />}
              </>                
            )
        })}


      {SiteConfig?.welcome_banner_image?.url && GQLPage?.menu?.slug === "home" && renderComponent === "true" && (
          count === 1 &&      
            <Modal
              show={modalShow}
              onHide={closeModal}
              size="lg"
              className="welcome_banner"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                  {/* <Modal.Title id="contained-modal-title-vcenter">
                      {TeamList.name}
                  </Modal.Title> */}
              </Modal.Header>
              <Modal.Body>
                  <img src={SiteConfig?.welcome_banner_image?.url} alt={SiteConfig?.welcome_banner_image?.alternativeText} />
              </Modal.Body>
            </Modal>
          
        )
      }
                
    </Layout>
  )

}

export default HomepageTemplate

export const query = graphql`
  query HomespageQueryCustom($pageId: ID!) {
    glstrapi {

      siteConfig {
        welcome_banner_image {
          alternativeText
          url
        }
      }

      page(id: $pageId) {
        id
        alias
        title
        choose_form
        form_to_email_id
        content
        layout
        publish
        page_class
        imagetransforms
        menu {
          label
          slug
          id
          link_type
          popular_search{
            popular_search{
              title
              content
            }
          }
          parent {
            label
            slug
            link_type
            parent {
              label
              slug
              link_type
              parent {
                label
                slug
                link_type
              }
            }
          }
        }
        banner_section {
          title
          show_search
          show_reviews
          id
          content
          button_cta_2 {
            theme
            target
            label
            id
            custom_link
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }
          button_cta_1 {
            theme
            target
            label
            custom_link
            id
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }
          banner_image {
            alternativeText
            url
            name
          }
        }
        seo_meta {
          title
          keywords
          description
          id
          image {
            alternativeText
            url
          }
        }

        add_components {
          __typename

          ... on GLSTRAPI_ComponentComponentsSliderComponent {
            id
            show_reviews
            show_property_search
            slider_module {
              id
              title
              content
              image {
                url
                alternativeText
                name
              }
              button_cta {
                label
                theme
                target
                custom_link
                id
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsTrustpilotComponent {
            id
            title
            content
            bg_image {
              url
              alternativeText
            }
          }

          ... on GLSTRAPI_ComponentComponentsLeadooChatComponent {
            id
            title
            script_code
            content
            bgrd_color:bg_color
          }


          ... on GLSTRAPI_ComponentComponentsHomeTilesComponent {
            home_tiles_block {
              id
              content
              title
              layout_type
              image {
                url
                alternativeText
              }
              button_cta {
                theme
                target
                label
                id
                custom_link
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }
          
          
          ... on GLSTRAPI_ComponentComponentsShowNewsInsight {
            id
            Title
            show
            select_type
          }

          ... on GLSTRAPI_ComponentComponentsShowFeaturedProperty {
            id
            show
            
            tab_list {
              add_tab
              id
              tab_name
            }
            background_clr:bg_color
          }

          ... on GLSTRAPI_ComponentComponentsVideoSection {
            id
            title
            content
            read_more_content
            embed_video_url
            bg_color
            button_CTA:Button_CTA {
              theme
              target
              label
              custom_link
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
            image {
              url
              alternativeText
            }
          }

          ... on GLSTRAPI_ComponentComponentsShowPropertyJourney {
            id
            show
          }
          ... on GLSTRAPI_ComponentComponentsShowCustomerReviews {
            id
            show
          }

          ... on GLSTRAPI_ComponentComponentsIntroSection {
            id
            link {
              id
              custom_link
              label
              link_type
              target
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
            text_block {
              id
              title
              description
            }
          }

          ... on GLSTRAPI_ComponentComponentsTilesBoxComponent {
            id
            heading_text
            content
            tiles_overlay_color
            number_of_tiles
            tiles_layout_type
            tiles_module {
              title
              id
              content
              image {
                url
                alternativeText
              }
              button_cta {
                label
                theme
                target
                custom_link
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }


          ... on GLSTRAPI_ComponentComponentsImageTextCarousel {
            id
            heading_text
            image_text_carousel_module {
              title
              content
              image {
                alternativeText
                url
              }
              button_cta {
                id
                label
                target
                theme
                custom_link
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }
   

        }
      }
    
    } 
}`